import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    redirect: '/home'
  },

  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },

  {
    path: '/aboutus',
    name: 'aboutUS',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutUS.vue')
  },
  // {
  //   path: '/help',
  //   name: 'FAQ',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Faq.vue')
  // },
  {
    path: '/privacy',
    name: 'Trueno',
    component: () => import(/* webpackChunkName: "about" */ '../views/Privacy.vue')
  },
  {
    path: '/user',
    name: 'Trueno',
    component: () => import(/* webpackChunkName: "about" */ '../views/Termservice.vue')
  },
  {
    path: '*',
    name: 'outher',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
